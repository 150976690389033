import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'antd/dist/antd.css';
import React, { Suspense } from 'react';
import { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Main from './pages/Main/Main';
import Search404 from "./pages/Search/Search404";
import Search from "./pages/Search/Search";
import Product from "./pages/Product/Product";
import Category from "./pages/Category/Category";
import CategoryList from './pages/Category/CategoryList';
import Cart from "./pages/Cart/Cart";
import Purchase from "./pages/Purchase/Purchase";
import Preloader from "./components/Preloader/Preloader";
import { initializeApp } from "./redux/appReducer";
import { setToken } from "./redux/profileReducer";
import './i18n';
import BestOfMonth from "./pages/BestOfMonth/BestOfMonth";

const Login = React.lazy(() => import('./pages/Authorization/Login/Login'));
const SignUp = React.lazy(() => import('./pages/Authorization/SignUp/SignUp'));
const SignUpConfirm = React.lazy(() => import('./pages/Authorization/SignUpConfirm/SignUpConfirm'));
const Profile = React.lazy(() => import('./pages/Profile/Profile'));
const ResetPasswordCode = React.lazy(() => import('./pages/Authorization/ResetPasswordCode/ResetPasswordCode'));
const ResetPassword = React.lazy(() => import('./pages/Authorization/ResetPassword/ResetPassword'));
const SetPassword = React.lazy(() => import('./pages/Authorization/SetPassword/SetPassword'));

/* Add Lazy loading to Order page */

function App() {

    const dispatch = useDispatch();

    const initialized = useSelector(state => state.app.initialized);
    const token = useSelector(state => state.profilePage.token)

    useEffect(() => {
        dispatch(initializeApp())
        dispatch(setToken(localStorage.getItem('Token')))
    }, [dispatch])
    if (!initialized) {
        return <Preloader />
    }

    // const requierAuth = (nextState, replaceState) => {
    //     if (!token) {
    //         replaceState({ nextPathname: nextState.location.pathname }, '/login')
    //     }
    // }

    return (
        <Router >
            <Suspense fallback={<Preloader />}>
                <Switch>
                    <Route exact path="/categories"><CategoryList /></Route>
                    <Redirect exact from="/category" to='/' />
                    {/* <Redirect from="/category" to='/categories'/> */}
                    <Route exact path="/category/:categoryName"><Category /></Route>
                    <Route exact path="/product/:name?"><Product /></Route>
                    <Route exact path="/best"><BestOfMonth /></Route>
                    <Route exact path="/"><Main /></Route>
                    <Route exact path="/login"><Login /></Route>
                    <Route exact path="/signup"><SignUp /></Route>
                    <Route exact path="/signup/confirm"><SignUpConfirm /></Route>
                    <Route exact path="/profile"><Profile /></Route>
                    <Route exact path="/reset-password"><ResetPassword /></Route>
                    <Route exact path="/reset-password/code"><ResetPasswordCode /></Route>
                    <Route exact path="/reset-password/set-password"><SetPassword /></Route>
                    <Route exact path="/search"><Search /></Route>
                    <Route exact path="/cart"><Cart /></Route>
                    <Route exact path="/purchase" render={({ location }) => (
                        token ? <Purchase /> :
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: location }
                                }} />
                    )} />
                    <Route exact path="*"><Search404 /></Route>
                </Switch>
            </Suspense>
        </Router>
    );
}

export default App;
