//Action Type
import {accountAPI} from "../api/api";

const SET_TOKEN = 'uzamazon/centersReducer/SET_TOKEN'
const SET_PROFILE = 'uzamazon/centersReducer/SET_PROFILE'
const SET_ERROR = 'uzamazon/profileReducer/SET_ERROR'
const SET_PASSWORD_RESET_STATE = 'uzamazon/profileReducer/SET_PASSWORD_RESET_STATE'
const SET_PROFILE_CHANGE_STATE = 'uzamazon/profileReducer/SET_PROFILE_CHANGE_STATE'
const SET_PHOTO = 'uzamazon/profileReducer/SET_PHOTO'

let initialState = {
    token: '',
    id: '',
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    error: '',
    passwordResetState: '',
    profileChangeState: '',
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token: action.token
            }
        case SET_PROFILE:
            return {
                ...state,
                ...action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }
        case SET_PASSWORD_RESET_STATE:
            return {
                ...state,
                passwordResetState: action.passwordResetState
            }
        case SET_PROFILE_CHANGE_STATE:
            return {
                ...state,
                profileChangeState: action.profileChangeState
            }
        case SET_PHOTO:
            return {...state, profile: {...state.profile, avatar: action.photo}}
        default:
            return state
    }
}

//Action Creators
export let setToken = (token) => ({type: SET_TOKEN, token})

let setProfile = (data) => ({
    type: SET_PROFILE,
    payload: {...data}
})

let setError = (error) => ({
    type: SET_ERROR,
    error
})

let setPasswordResetState = (passwordResetState) => ({
    type: SET_PASSWORD_RESET_STATE,
    passwordResetState
})

let setProfileChangeState = (profileChangeState) => ({
    type: SET_PROFILE_CHANGE_STATE,
    profileChangeState
})
const setPhoto = (photo) => ({type: SET_PHOTO, photo})

//Thunk

export let registerAcc = (registerData) => async (dispatch) => {
    let {data, error} = await accountAPI.registerAcc(registerData)
    return {data, error}
}

export let getLogin = (userData) => async (dispatch) => {
    let {data, error} = await accountAPI.getLogin(userData)
    if (data) {
        localStorage.setItem('Token', data.token);
        dispatch(setToken(data.token))
    } else {
        dispatch(setError(error));
    }
    return data
}

export let tokenAcc = (code) => async (dispatch) => {
    let data = await accountAPI.tokenAcc(code);
    let error;
    if (data.token) {
        dispatch(setToken(data.token))
    } else {
        error = data;
    }
    return {data, error};
}

export let cleanError = () => async (dispatch) => {
    dispatch(setError(''));
}

export let getProfile = () => async (dispatch) => {
    let data = await accountAPI.getProfile()
    dispatch(setProfile(data))
}

export let putProfile = (profileData) => async (dispatch) => {
    let data = await accountAPI.putProfile(profileData);
    return data;
}

export let deleteProfile = () => async (dispatch) => {
    let data = await accountAPI.deleteProfile();
    dispatch(logOut())
    return data;
}

export let putPassword = (passwordData) => async (dispatch) => {
    let data = await accountAPI.putPassword(passwordData);
    dispatch(setProfileChangeState(data));
}

export let cleanPasswordReset = () => async (dispatch) => {
    dispatch(setPasswordResetState(''));
}

export let postPasswordReset = (passwordResetState) => async (dispatch) => {
    let data = await accountAPI.postPasswordReset(passwordResetState);
    dispatch(setPasswordResetState(data));
}

export let postPasswordResetCode = (code) => async (dispatch) => {
    let data = await accountAPI.postPasswordResetCode(code);
    dispatch(setPasswordResetState(data.data));
}

export let postSetPasswordReset = (newPasswords) => async (dispatch) => {
    let data = await accountAPI.postSetPasswordReset(newPasswords);
    dispatch(setPasswordResetState(data));
}


export let logOut = () => async (dispatch) => {
    dispatch(setToken(''))
    localStorage.removeItem('Token')
}

export const setPhotoProfile = (file) => async (dispatch) => {
    let data = await accountAPI.setPhoto(file)
    return data
}


export default profileReducer
