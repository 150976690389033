const Star = () => {
    return (
        <svg viewBox="0 0 17 16" fill='currentColor' xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7675 5.36059L10.6517 5.12384L8.42439 0.571893L8.42417 0.571461C8.41149 0.545481 8.39101 0.525233 8.36677 0.51321L8.36619 0.512922C8.30739 0.48366 8.22961 0.505593 8.19541 0.573523C8.19528 0.573783 8.19515 0.574044 8.19502 0.574305L5.9689 5.12384L5.85305 5.36059L5.59226 5.3988L0.609752 6.12877L0.608543 6.12895C0.583282 6.13259 0.558204 6.14468 0.537308 6.1659C0.513375 6.19104 0.499532 6.22549 0.500012 6.26199C0.500496 6.2988 0.515481 6.33308 0.540376 6.35755L0.540409 6.35758L4.14532 9.90062L4.33157 10.0837L4.28775 10.3411L3.43607 15.3441L3.43596 15.3448C3.43165 15.3699 3.43444 15.3956 3.44384 15.4189C3.45322 15.4422 3.46867 15.4618 3.4879 15.4759C3.50708 15.49 3.52938 15.498 3.55222 15.4997C3.57506 15.5013 3.59809 15.4965 3.61878 15.4855L3.61956 15.4851L8.07615 13.1231L8.3103 12.999L8.54445 13.1231L13.001 15.4851L13.0033 15.4863C13.0243 15.4976 13.0517 15.5025 13.0789 15.4977L13.0796 15.4976C13.1473 15.4858 13.1974 15.4193 13.1846 15.3445L13.1845 15.3441L12.3328 10.3411L12.289 10.0837L12.4753 9.90062L16.0793 6.35848C16.0793 6.35843 16.0794 6.35838 16.0794 6.35833L10.7675 5.36059ZM10.7675 5.36059L11.0283 5.3988M10.7675 5.36059L11.0283 5.3988M11.0283 5.3988L16.0093 6.12854L11.0283 5.3988ZM11.8456 9.93231L14.6578 7.16978L15.3759 6.46433L14.3799 6.31837L10.496 5.74926L8.75938 2.20155L8.3103 1.28414L7.86122 2.20155L6.12458 5.74926L2.24074 6.31837L1.24514 6.46426L1.96272 7.16966L4.77502 9.93418L4.11044 13.837L3.93992 14.8384L4.83746 14.3627L8.31041 12.5223L11.7833 14.3609L12.6806 14.8359L12.5102 13.835L11.8456 9.93231Z" stroke="#E9456A" />
        </svg>

    )
}

export default Star;
