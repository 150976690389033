import classes from './Search404.module.scss'
import page404 from '../../assets/img/404.png'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Carousel from "../../components/Carousel/Carousel";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getMonthlyBest} from "../../redux/monthlyBestReducer";
import {useEffect} from "react";

const Search404 = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const monthlyBest = useSelector(state => state.monthlyBestPage.monthlyBest);
    useEffect(() => {
        dispatch(getMonthlyBest());
    }, [])
    return (
        <div className={classes.search404}>
            <Header/>
            <div className={classes.content404}>
                <div className="container">
                    <h2>Результаты поиска по запросу “Nike”</h2>
                    <p>Упс... Мы ничего не нашли</p>
                    <img src={page404} alt="404"/>
                    <p className={classes.like}>{t("likeProducts")}</p>
                    <Carousel items={monthlyBest ? monthlyBest.products : null} centered/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Search404
