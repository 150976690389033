import './Grid.scss';
import Card from "../Card/Card";

const Grid = ({ items, noPriceCards, className }) => {
    return (
        <>
            <div className={`grid${className ? ' ' + className : ''}`}>
                {items && items.map((card, key) => (
                    <div className='grid-child' key={key}>
                        <Card
                            key={`${key}_${card && card.slug}`}
                            image={card.image}
                            price={noPriceCards ? false : card.price}
                            title={card.title}
                            activeStars={card.rate}
                            linkTo={`/product/${card.slug}`}
                        />
                    </div>
                ))}
                {items && items.length <= 1 && <div className='filler'></div>}
                {items && items.length <= 2 && <div className='filler'></div>}
                {items && items.length <= 3 && <div className='filler'></div>}
                {items && items.length <= 4 && <div className='filler'></div>}
            </div>
        </>

    )
}


export default Grid;
