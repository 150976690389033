import './Category.scss';

import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { cleanCategory, getCategory } from '../../redux/categoryReducer';

import Header from "../../components/Header/Header";
import ProductList from "../../components/ProductList/ProductList"
import Footer from "../../components/Footer/Footer";

import leadingPreviewImage from "../../assets/img/cat_clothing_preview.jpg";
import bottomPreviewImage from "../../assets/img/cat_clothing_bottom_preview.jpg";

const Category = (props) => {
    const { categoryName } = useParams();
    const categoryState = useSelector(state => state.categoryPage.category);

    return (
        <>
            <Header />
            <section className='banner-top' style={{ backgroundImage: `url(${categoryState && categoryState.params && categoryState.params.category && categoryState.params.category.banner1})` }} />
            {``}
            <ProductList
                pathName={categoryName}
                productsState={categoryState}
                getFunc={getCategory}
                cleanFunc={cleanCategory}
            />
            <Footer />
        </>
    );
}

export default Category;
